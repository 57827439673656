import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Img,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { HiBadgeCheck, HiUsers, HiCurrencyDollar } from 'react-icons/hi'
import { FaBlackTie } from 'react-icons/fa'
import { StaticImage } from 'gatsby-plugin-image'
import Feature from './Feature'

const SplitFeature = () => {
  return (
    <Box as="section" bg={mode('gray.50', 'gray.800')} py="24">
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Flex justify="space-between" direction={{ base: 'column', lg: 'row' }}>
          <Box maxW={{ lg: 'lg' }}>
            <Box mb={{ lg: '4rem' }}>
              <Heading
                lineHeight="shorter"
                size="2xl"
                letterSpacing="tight"
                color={mode('gray.900', 'white')}
                fontWeight="extrabold"
              >
                Serving <br />
                <Box as="span" color={mode('orange.500', 'orange.400')}>
                  Commercial
                </Box>
              </Heading>
              <Text mt="4" fontSize="2xl" color={mode('gray.600', 'gray.400')} maxW={{ lg: 'md' }}>
                A one stop solution for your commercial lawn and property maintenance needs.
              </Text>
            </Box>
            <SimpleGrid
              flex="1"
              columns={{ base: 1, md: 2 }}
              spacing={{ base: '3rem', md: '2rem' }}
              mt={{ base: 4, lg: 0 }}
            >
              <Feature title="Impeccable Quality" icon={<HiBadgeCheck />}>
                We obsess over the little things to make sure your property looks better than it ever has before.
              </Feature>
              <Feature title="Customer Focused" icon={<HiUsers />}>
                We care about our customers and guarantee satisfaction. If you are not happy, we fix it.
              </Feature>
              <Feature title="Professional Appearance" icon={<FaBlackTie />}>
                We always maintain a professional appearance. We understand that we are an extension of your business while on your property.
              </Feature>
              <Feature title="Transparent Billing" icon={<HiCurrencyDollar />}>
                Never be surprised by an invoice again! We estimate everything upfront so you know what to expect.
              </Feature>


            </SimpleGrid>
          </Box>
          <Box flex="1" px={{ base: '6', md: '8' }} mt={{ base: 6, lg: 0 }}>
            <StaticImage
              src="../../images/lawn-backyard.jpg"
              alt="Bullseye Lawn Care Example"
              placeholder="blurred"
              aspectRatio={.9}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default SplitFeature
import React from "react"
import { Box, Button, Flex, Heading, Stack, Img, Text, useColorModeValue as mode } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const QuoteFormHero = ({ image, alt }) => {
  return (
    <Box bg="gray.800" as="section" minH="140px" position="relative">
      <Box py={{ base: "20", lg: "32" }} position="relative" zIndex={1} >
        <Stack
          spacing={{ base: '3rem', lg: '2rem' }}
          align={{ base: 'center', lg: 'center' }}
          justify="center"
          direction={{ base: "column", lg: "row" }}
        >
          <Box color="white" flex="1" maxW="700px" mx={{ base: 'auto', lg: '4' }} textAlign={{ base: 'center', lg: 'left' }}>
            <Box>
              <Heading as="h1" size="3xl" fontWeight="extrabold" px={{ base: '4', lg: '1' }}>
                Trusted Sioux Falls Lawn Care Professionals
              </Heading>
              <Text fontSize={{ md: '2xl' }} mt="4" maxW={{ base: "3xl", lg: "lg" }} px={{ base: '4', lg: '1' }}>
              Bullseye Lawn Care provides professional lawn care and property maintenance services that always hit the mark.
              </Text>
            </Box>
          </Box>
          <Box
            flex="1"
            maxW={{ base: "100%", lg: "500px" }}
            mx={{ lg: '4' }}
            textAlign={{ base: 'center', lg: 'left' }}
            px={{ base: '4', lg: '1' }}
          >
            <Box maxW="xl">
              <Box
                minW={{ md: '420px' }}
                rounded="xl"
                bg={mode('white', 'gray.700')}
                shadow="lg"
                px={{ base: '8', md: '10' }}
                pt={{ base: '8', md: '12' }}
                pb="8"
              >
                <Heading
                  color={mode('gray.600', 'gray.200')}
                  as="h1"
                  size="2xl"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                >
                  Interested?
                </Heading>
                <Text
                  my="3"
                  fontSize={{ base: 'l', md: 'xl' }}
                  fontWeight="bold"
                  color={mode('gray.500', 'inherit')}
                >
                  Give us a call or request a free quote.
                </Text>
                <Text
                  my="3"
                  fontSize={{ base: 'xl', md: '2xl' }}
                  fontWeight="bold"
                  color={mode('gray.500', 'inherit')}
                >
                (605) 204-5458
              </Text>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4" mt="8">
                <Button size="lg" minW="210px" colorScheme="bullseyeOrange" height="14" px="8" as={GatsbyLink}
              to="/request-quote">
                  Get a Free Quote
                </Button>
                <Button
                  size="lg"
                  colorScheme="bullseyeGreen"
                  height="14"
                  px="8"
                  shadow="base"
                  variant="outline"
                  as={GatsbyLink}
              to="/company/contact"
                >
                  Contact Us
                </Button>
              </Stack>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          <Img as={GatsbyImage}
            image={image}
            alt={alt}
            loading="eager"
            width={{ base: "1024px", lg: "auto" }}
            height={{ base: "1535px", lg: "auto" }}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default QuoteFormHero
import { Box, Stack, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

interface FeatureProps {
  icon: React.ReactElement
  title: string
  children: React.ReactNode
  iconLocation?: string
}

const Feature = (props: FeatureProps) => {
  const { title, children, icon, iconLocation = 'above' } = props

  if (iconLocation === 'above') {
    return (
      <Flex justify="start" direction={{ base: 'row', md: 'column' }}>
        <Box color={mode('bullseyeOrange.500', 'bullseyeOrange.400')} fontSize="2.5rem" width="3rem">
          {icon}
        </Box>
        <Stack mt={{ base: 1, md: 4 }} ml={{ base: 4, md: 0}}>
          <Text as="h3" color={mode('bullseyeOrange.500', 'bullseyeOrange.400')} fontSize="xl" fontWeight="bold">
            {title}
          </Text>
          <Text paddingEnd="6" lineHeight="tall">
            {children}
          </Text>
        </Stack>
      </Flex>
    )
  }
  else if (iconLocation === 'left') {
    return (
      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
        <Box color={mode('bullseyeGreen.500', 'bullseyeGreen.400')} fontSize="6xl">{icon}</Box>
        <Stack spacing="1">
          <Text color={mode('bullseyeGreen.500', 'bullseyeGreen.400')} fontWeight="extrabold" fontSize="lg">
            {title}
          </Text>
          <Box color={mode('gray.600', 'gray.400')}>{children}</Box>
        </Stack>
      </Stack>
    )
  }
}

export default Feature
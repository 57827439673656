import React from "react"
import {
  Box,
  Button,
  Stack,
  Grid,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { getImage, withArtDirection } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Container from "../components/Container"
import FeatureCard from "../components/landingpage/FeatureCard"
import QuoteFormHero from "../components/landingpage/QuoteFormHero"
import Meta from "../components/Meta"
import SplitFeature from "../components/landingpage/SplitFeature"

const Home = ({ data }) => {
  const images = withArtDirection(getImage(data.heroImageDesktop), [
    {
      media: "(max-width: 1024px)",
      image: getImage(data.heroImageMobile),
    },
  ])

  return (
    <Layout>
      <Meta
        title="Sioux Falls Lawn Care Experts"
        description="Bullseye Lawn Care provides high quality lawn and property maintenance service in the Sioux Falls area."
      />
      <QuoteFormHero image={images} alt="Bullseye Lawn Care Lawn Example" />
      <Box
        pt={50}
        width={`100%`}
        background={useColorModeValue(`gray.100`, `gray.600`)}
        borderBottomWidth="1px"
      >
        <Container mt="0">
          <Box textAlign="center" mb="20">
            <Heading
              size="2xl"
              lineHeight="normal"
              color={useColorModeValue("green.600", "green.400")}
              letterSpacing="tight"
              fontWeight="extrabold"
            >
              Bullseye Services
            </Heading>
          </Box>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={10}
            px={{ md: 12 }}
            mb={`20`}
          >
            <FeatureCard
              image={data.lawnImage.childImageSharp.gatsbyImageData}
              title="Lawn Care"
              link="lawn-care"
            >
              We like clean-cut, green grass with straight mow lines. If you
              like that too, then you will find we do everything to help you
              keep your lawn healthy and looking great.
            </FeatureCard>
            <FeatureCard
              image={data.shrubImage.childImageSharp.gatsbyImageData}
              title="Tree & Shrub"
              link="tree-shrub-care"
            >
              Your property is more than just the lawn. We offer additional
              services for your trees and shrubs to keep your entire property in
              top-shape.
            </FeatureCard>
            <FeatureCard
              image={data.snowImage.childImageSharp.gatsbyImageData}
              title="Snow Removal"
              link="snow-removal"
            >
              Nobody likes waking up to sidewalks and a driveway full of snow.
              We get up early so you don't have to.
            </FeatureCard>
          </Grid>
        </Container>
      </Box>
      <SplitFeature />
      <Box
        pt={50}
        width={`100%`}
        background={useColorModeValue(`gray.100`, `gray.600`)}
        borderBottomWidth="1px"
      >
        <Container mt="0">
          <Box textAlign="center" mb="20">
            <Heading
              size="2xl"
              lineHeight="normal"
              color={useColorModeValue("green.600", "green.400")}
              letterSpacing="tight"
              fontWeight="extrabold"
            >
              Recent Posts from the Bullseye Blog
            </Heading>
          </Box>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={10}
            px={{ md: 12 }}
            mb={`20`}
          >
            {data.allContentfulBlogPost.nodes.map(post => (
              <FeatureCard
                image={post.blogImage.gatsbyImage}
                title={post.title}
                link={`blog/${post.urlSlug}`}
                blogPost={true}
              >
                {post.blogSummary.blogSummary}
              </FeatureCard>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box as="section" bg={useColorModeValue("gray.200", "gray.700")}>
        <Box
          maxW="2xl"
          mx="auto"
          px={{ base: "6", lg: "8" }}
          py={{ base: "16", sm: "20" }}
          textAlign="center"
        >
          <Heading
            as="h2"
            size="2xl"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            Ready to make your neighbors jealous?
          </Heading>
          <Text mt="4" fontSize="lg">
            If you want to make your lawn the talk of the neighborhood, give us
            a call or request a free quote!
          </Text>
          <Text
            mt="4"
            fontSize="2xl"
            color={useColorModeValue("orange.500", "orange.400")}
          >
            (605) 204-5458
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing="4"
            mt="8"
            align="center"
            justify="center"
          >
            <Button
              size="lg"
              minW="210px"
              colorScheme="bullseyeOrange"
              height="14"
              px="8"
              as={GatsbyLink}
              to="/request-quote"
            >
              Get a Free Quote
            </Button>
            <Button
              size="lg"
              bg="white"
              color="gray.800"
              _hover={{ bg: "gray.50" }}
              height="14"
              px="8"
              shadow="base"
              fontSize="md"
              minW="210px"
              as={GatsbyLink}
              to="/company/contact"
            >
              Contact Us
            </Button>
          </Stack>
        </Box>
      </Box>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    heroImageDesktop: file(
      relativePath: { eq: "bullseyelawnexamplecircles.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          aspectRatio: 1.778
          formats: [AUTO, WEBP]
          transformOptions: { cropFocus: SOUTHWEST }
        )
      }
    }
    heroImageMobile: file(
      relativePath: { eq: "bullseyelawnexamplecircles.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          aspectRatio: 0.667
          formats: [AUTO, WEBP]
          transformOptions: { cropFocus: SOUTHWEST }
        )
      }
    }
    lawnImage: file(relativePath: { eq: "services/lawn-closeup.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1.778
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    shrubImage: file(relativePath: { eq: "services/shrub-pruning.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1.778
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    snowImage: file(relativePath: { eq: "services/snow-road.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1.778
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    allContentfulBlogPost(sort: { fields: postDate, order: DESC }) {
      nodes {
        blogSummary {
          blogSummary
        }
        title
        blogImage {
          gatsbyImage(width: 300)
        }
        urlSlug
      }
    }
  }
`

export default Home
